import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AuthService } from './services/auth.service';
import { smartTimer } from '@pstg/smart-timer';
import { mergeMap } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(
        private router: Router,
        private authService: AuthService,
        private primengConfig: PrimeNGConfig,
    ) { }

    ngOnInit() {
        this.primengConfig.ripple = true;

        smartTimer(0, 60000, 60000).pipe(
            mergeMap(() => this.authService.isLoggedIn()),
        ).subscribe(isLoggedIn => {
            const isOnLoginScreen = this.router.url === '/auth/login';
            if (isLoggedIn) {
                if (isOnLoginScreen) {
                    this.router.navigate(['/pages']);
                }
            } else {
                if (!isOnLoginScreen) {
                    this.router.navigate(['/auth/login']);
                }
            }
        });
    }
}
