import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LayoutService } from './service/app.layout.service';
import { AuthService, UserRole } from '../services/auth.service';
import { distinctUntilChanged, map } from 'rxjs';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent {
    model: any[] = [];

    constructor(
        public layoutService: LayoutService,
        public authService: AuthService,
    ) {
        this.authService.currentUser$.pipe(
            takeUntilDestroyed(),
            map(user => user?.role),
            distinctUntilChanged(),
        ).subscribe(role => {
            this.updateMenu(role);
        });
    }

    updateMenu(role: UserRole): void {
        if (role === UserRole.ADMIN) {
            this.model = [
                {
                    label: 'Administrace',
                    items: [
                        { label: 'Zákaznící', icon: 'pi pi-fw pi-users', routerLink: ['/pages/users'] },
                        { label: 'Solární elektrárny', icon: 'pi pi-fw pi-sitemap', routerLink: ['/pages/solar-plants'] },
                        { label: 'Ceny elektřiny', icon: 'pi pi-fw pi-dollar', routerLink: ['/pages/energy-price'] },
                        { label: 'Odhásit se', icon: 'pi pi-fw pi-sign-in', routerLink: ['/pages/logout'] },
                    ],
                }
            ]
        }

        if (role === UserRole.USER) {
            this.model = [
                {
                    label: 'Smart Energy',
                    items: [
                        { label: 'Přehled', icon: 'pi pi-fw pi-home', routerLink: ['/pages'] },
                        { label: 'Nastavení střídače', icon: 'pi pi-fw pi-cog', routerLink: ['/pages/inverter/settings'] },
                        { label: 'Cena elektřiny', icon: 'pi pi-fw pi-dollar', routerLink: ['/pages/energy-price'] },
                        { label: 'Statistiky', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/pages/charts'] },
                        { label: 'Předpověď výroby', icon: 'pi pi-fw pi-sun', routerLink: ['/pages/solar-forecast'] },
                        { label: 'Odhásit se', icon: 'pi pi-fw pi-sign-in', routerLink: ['/pages/logout'] },
                    ],
                },
            ];
        }
    }
}
